<script>
import { Color, DirectionalLight, LoadingManager, MathUtils, Mesh, PointLight, RepeatWrapping, TextureLoader, Vector3, AmbientLight } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { Camera, GridHelper, PerspectiveCamera, Renderer, Scene } from '../../components/three';
import bus from './bus';

export default {
  props: {
    productId: { type: [String, Number], required: false },
    templateId: { type: [String, Number], required: false },
    positionX: { type: Number, required: false, default: 0.0 },
    positionY: { type: Number, required: false, default: 0.0 },
    positionZ: { type: Number, required: false, default: 0.0 },
    rotationX: { type: Number, required: false, default: 0.0 },
    rotationY: { type: Number, required: false, default: 0.0 },
    rotationZ: { type: Number, required: false, default: 0.0 },
    backgroundColor: { type: String, required: false },
  },
  data() {
    return {
      coordinates: {
        x: this.positionX,
        y: this.positionY,
        z: this.positionZ,
      },
      rotation: {
        x: this.rotationX,
        y: this.rotationY,
        z: this.rotationZ,
      },
      product: {},
      materials: {},
    };
  },
  components: { Camera, GridHelper, PerspectiveCamera, Renderer, Scene },
  async mounted() {
    bus.$on('updateMaterial', (materialGroup, value, texture) => {
      this.updateMaterial(materialGroup, texture);
    });
    bus.$on('updateTemplate', (template) => {
      this.updateTemplate(template);
    });
    if (this.productId) {
      await fetch(`/products/${this.productId}.json`, { headers: { Accept: 'application/json' } })
        .then((response) => response.json())
        .then((data) => {
          this.product = data;
        });
      await this.loadMaterials();
      this.loadModel();
    }
    else if(this.templateId) {
      this.updateTemplate(this.templateId);
    }
    const renderer = this.$refs.renderer.renderer;
    const camera = this.$refs.renderer.camera;
    const scene = this.$refs.renderer.scene;
    const container = this.$refs.container;

    // renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    container.replaceChildren();
    container.appendChild(renderer.domElement);
    scene.add(camera);
    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();
    const orbitControls_attrs = {};
    if (orbitControls_attrs != null) {
      this.orbitControls = new OrbitControls(camera, renderer.domElement);
      this.orbitControls.target.set(0, 0.5, 0);
      this.orbitControls.maxPolarAngle = Math.PI * 0.5;
      this.orbitControls.maxAzimuthAngle = Math.PI * 0.5;
      this.orbitControls.minAzimuthAngle = -Math.PI * 0.5;
      this.orbitControls.minDistance = 1000;
      this.orbitControls.maxDistance = 2000;
    }
    this.resizeListener = () => {
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      this.orbitControls.update();
      this.render();
    };
    window.addEventListener('resize', this.resizeListener, false);

    const ambientLight = new AmbientLight(0x404040, 0.5);
    scene.add(ambientLight);

    const fillLight = new DirectionalLight(0xffffff, 0.5);
    fillLight.position.set(-100, 150, -100);
    scene.add(fillLight);

    const cameraLight = new PointLight(0xffffff, 0.3);
    camera.add(cameraLight);

    const sideLight1 = new PointLight(0xffccaa, 0.3); // Tom quente
    sideLight1.position.set(100, 50, 100);
    scene.add(sideLight1);

    const sideLight2 = new PointLight(0xaaccff, 0.3); // Tom frio
    sideLight2.position.set(-100, 50, -100);
    scene.add(sideLight2);

    this.render = () => {
      renderer.render(scene, camera);
    };

    var animate = () => {
      if (renderer != null) {
        requestAnimationFrame(animate);
        this.orbitControls.update();
        this.render();
      }
    };

    animate();
  },
  methods: {
    async loadMaterials() {
      await fetch(`/products/${this.productId}/materials.json`)
        .then((response) => response.json())
        .then((response) => {
          this.materials = response;
        });
    },
    loadModel() {
      var manager = new LoadingManager();
      // eslint-disable-next-line no-unused-vars
      manager.onProgress = function (item, loaded, total) {};
      const mtlLoader = new MTLLoader(manager);
      if (this.product.model_url) {
        if (this.product.material_url) {
          mtlLoader.load(this.product.material_url, (materials) => {
            materials.preload();
            const loader = new OBJLoader(manager).setMaterials(materials);
            loader.load(
              this.product.model_url,
              (object) => {
                this.$refs.renderer.scene.add(object);
                object.position.set(this.coordinates.x, this.coordinates.y, this.coordinates.z);
                object.rotation.set(
                  MathUtils.degToRad(this.rotation.x),
                  MathUtils.degToRad(this.rotation.y),
                  MathUtils.degToRad(this.rotation.z)
                );
                for (const [key, value] of Object.entries(this.materials)) {
                  this.updateMaterial(key, value[0].image_url);
                }
              },
              (xhr) => {
                if (xhr.lengthComputable) {
                  var percentComplete = (xhr.loaded / xhr.total) * 100;
                  console.log(Math.round(percentComplete, 2) + '% downloaded');
                }
              }
            );
          });
        } else {
          const loader = new OBJLoader(manager);
          loader.load(
            this.product.model_url,
            (object) => {
              this.$refs.renderer.scene.add(object);
              object.position.set(this.coordinates.x, this.coordinates.y, this.coordinates.z);
              object.rotation.set(
                MathUtils.degToRad(this.rotation.x),
                MathUtils.degToRad(this.rotation.y),
                MathUtils.degToRad(this.rotation.z)
              );
              for (const [key, value] of Object.entries(this.materials)) {
                this.updateMaterial(key, value[0].image_url);
              }
            },
            (xhr) => {
              if (xhr.lengthComputable) {
                var percentComplete = (xhr.loaded / xhr.total) * 100;
                console.log(Math.round(percentComplete, 2) + '% downloaded');
              }
            }
          );
        }
      }
    },
    updateMaterial(materialGroup, textureUrl) {
      const imageUrl = textureUrl;
      const group = materialGroup.toLowerCase();

      var updateMaterial = function (material, texture) {
        if (texture == null) {
          material.color = new Color('white');
          material.map = null;
        } else {
          material.color = null;
          material.map = texture;
        }
        material.needsUpdate = true;
      };
      var maxAnisotropy = this.$refs.renderer.renderer.capabilities.getMaxAnisotropy();
      var textureLoader = new TextureLoader();
      var texture = textureLoader.load(imageUrl);
      texture.anisotropy = maxAnisotropy;
      texture.wrapS = texture.wrapT = RepeatWrapping;
      texture.repeat.set(0.05, 0.05);

      this.$refs.renderer.scene.traverse(function (child) {
        if (child instanceof Mesh) {
          if (Array.isArray(child.material)) {
            for (var m = 0; m < child.material.length; m++) {
              if (group == child.material[m].name.toLowerCase()) {
                updateMaterial(child.material[m], texture);
              }
            }
          } else {
            if (group == child.material.name.toLowerCase()) {
              updateMaterial(child.material, texture);
            }
          }
        }
      });
    },
    updateTemplate(template) {
      fetch(`/product_templates/${template}.json`)
        .then((response) => response.json())
        .then((data) => {
          const scene = this.$refs.renderer.scene;
          const camera = this.$refs.renderer.camera;

          for (let i = scene.children.length - 1; i >= 0; i--) {
            if (scene.children[i].type === 'Group' || scene.children[i].type === 'Mesh') scene.remove(scene.children[i]);
          }
          const position = data.scene.camera.position;
          camera.position.set(position[0], position[1], position[2]);
          const rotation = data.scene.camera.rotation;
          camera.rotation.set(rotation[0], rotation[1], rotation[2]);
          camera.lookAt(new Vector3());
          camera.updateProjectionMatrix();
          const loader = new OBJLoader(new LoadingManager());
          data.scene.objects.forEach((obj) => {
            loader.load(obj.url, (object) => {
              object.position.set(obj.position[0], obj.position[1], obj.position[2]);
              object.rotation.set(obj.rotation[0], obj.rotation[1], obj.rotation[2]);
              scene.add(object);
              for (const [key, value] of Object.entries(this.materials)) {
                this.updateMaterial(key, value[0].image_url);
              }
            });
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <Renderer ref="renderer">
      <PerspectiveCamera :position="{ x: 1200, y: 1100, z: 1700 }" />
      <Scene :background="backgroundColor"></Scene>
    </Renderer>
    <div style="width: 100%; height: 450px" class="mb-3" ref="container"></div>
  </div>
</template>
