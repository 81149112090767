import { Controller } from '@hotwired/stimulus';
import Vue from 'vue';
import Model from '../components/model.vue';
import bus from '../components/bus';

export default class ModelController extends Controller {
  declare vueInstance: Vue | null;
  declare containerTarget: HTMLElement;
  declare hasContainerTarget: Boolean;
  declare productIdValue: string | number | null;
  declare templateIdValue: string | number | null;
  declare positionXValue: number | null;
  declare positionYValue: number | null;
  declare positionZValue: number | null;
  declare rotationXValue: number | null;
  declare rotationYValue: number | null;
  declare rotationZValue: number | null;
  declare backgroundValue: string;

  static targets = ['container'];
  static values = {
    productId: String,
    templateId: String,
    positionX: Number,
    positionY: Number,
    positionZ: Number,
    rotationX: Number,
    rotationY: Number,
    rotationZ: Number,
    background: String,
  };

  connect(): void {
    const target = document.createElement('div');
    if (this.hasContainerTarget) {
      this.containerTarget.appendChild(target);
    } else {
      this.element.appendChild(target);
    }
    this.vueInstance = new Vue({
      el: target,
      components: {
        Model,
      },
      template: `<model
      template-id="${this.templateIdValue}"
      product-id="${this.productIdValue}"
      :position-x="${this.positionXValue}"
      :position-y="${this.positionYValue}"
      :position-z="${this.positionZValue}"
      :rotation-x="${this.rotationXValue}"
      :rotation-y="${this.rotationYValue}"
      :rotation-z="${this.rotationZValue}"
      background-color="${this.backgroundValue}" />`,
    });
  }

  disconnect(): void {
    if (this.vueInstance) {
      this.vueInstance.$destroy();
      this.vueInstance = null;
    }
    this.element.replaceChildren();
  }

  updateMaterial(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const materialGroup = target.dataset.group;
    const value = target.value;
    const texture = target.selectedOptions[0].dataset.texture;
    bus.$emit('updateMaterial', materialGroup, value, texture);
  }

  updateTemplate(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    bus.$emit('updateTemplate', value);
  }
}
